import { NgModule ,ErrorHandler} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from "./app.component";
import { AuthGuard } from './shared/guard/auth.guard';
import { GlobalErrorHandler } from './global-error-handler';


const routes: Routes = [
  {path: '', component:AppComponent, loadChildren: './layout/layout.module#LayoutModule'},
  {path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule'},
  {path: '**', redirectTo: 'not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{provide: ErrorHandler, useClass: GlobalErrorHandler}]
})
export class AppRoutingModule { }
