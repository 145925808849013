import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot,Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './../services/login.service';



@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: LoginService,
    private myRoute: Router){
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(this.auth.isAuthenticated()){
      return true;
    }else{
      this.myRoute.navigate([""]);
      return false;
    }
  }

}
