import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })

export class ReplacePipe implements PipeTransform {
    transform(value: string, expr: any): string {
        if (value) {
            let newValue = value.replace("&#8211;", "").replace("&#038;", "");
            // console.log('old value======>', value, 'newValue=========>', newValue);
            return `${newValue}`;
        }
    }
}
