import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/map'
import { environment } from './../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class UserService {

    constructor(private http: HttpClient, private cookie: CookieService) { }

    public userProfile = JSON.parse(this.cookie.get('userData'));

    /**
     * Used for get profile data of current user
     * @returns Observable
     * @param -
     */
    public getProfileDetails(): Observable<any> {

        const formData = new FormData();
        formData.append('user_id', this.userProfile.user_id);
        formData.append('auth_token', this.cookie.get('token'));

        return this.http.post(environment.apiUrl + 'api/profile/get_profile_again', formData).map((res) => {
            return res;
        });
    }

    /**
     * Used for get billing data of current user
     * @returns Observable
     * @param -
     */
    public getBillingData(): Observable<any> {
        //let params = `user_id=${this.userProfile.user_id}&auth_token=${this.cookie.get('token')}`;

        
        const formData = new FormData();
        formData.append('user_id', this.userProfile.user_id);
        formData.append('auth_token', this.cookie.get('token'));

        return this.http.post(environment.apiUrl + 'api/profile/get_billing_info', formData).map((res) => {
            return res;
        });
    }

    /**
     * Get logged in user files
     */
    public getMyFiles(): Observable<any> {
        //let params = `user_id=${this.userProfile.user_id}&auth_token=${this.cookie.get('token')}`;
        const formData = new FormData();
        formData.append('user_id', this.userProfile.user_id);
        formData.append('auth_token', this.cookie.get('token'));
        return this.http.post(environment.apiUrl + 'api/profile/my_files', formData).map((res) => {
            return res;
        });
    }

    /**
    * Used for get profile data of current user
    * @returns Observable
    * @param -
    */
    public getUserProfileDetails(): Observable<any> {
        //let params = `user_id=${this.userProfile.user_id}&auth_token=${this.cookie.get('token')}`;
        const formData = new FormData();
        formData.append('user_id', this.userProfile.user_id);
        formData.append('auth_token', this.cookie.get('token'));
        return this.http.post(environment.apiUrl + 'api/profile/get_profile_again', formData).map((res) => {
            return res;
        });
    }


    /**
    * Get logged in user files
    */
    public updateUserProfile(formdata: any, courseArray): Observable<any> {
        // console.log('services',courseArray)
        let about_me = formdata.about_me;
        let phone_number = formdata.phone_number;
        let clubs_organizations = formdata.clubs_organizations;
        let graduation_year = formdata.grad_year;
        let select_college_id = formdata.major_college.id;
        let select_college_name = formdata.major_college.name;
        let select_major_subject_id = formdata.major_subject.id;
        // let select_school_id = formdata.major_school.id;
        // let select_school_name = formdata.major_school.name
        
        //let params = `user_id=${this.userProfile.user_id}&auth_token=${this.cookie.get('token')}&about_me=${about_me}&phone_number=${phone_number}&clubs_organizations=${clubs_organizations}&graduation_year=${graduation_year}&major_college=${select_college_id}&major_subject=${select_major_subject_id}&select_courses=${courseArray}`;


        const uploadData = new FormData();
        uploadData.append('user_id', this.userProfile.user_id);
        uploadData.append('auth_token', this.cookie.get('token'));
        uploadData.append('about_me', about_me);
        uploadData.append('phone_number', phone_number);
        uploadData.append('clubs_organizations', clubs_organizations);
        uploadData.append('major_college', select_college_id);
        uploadData.append('major_subject', select_major_subject_id);
        uploadData.append('select_courses', courseArray);
        // uploadData.append('major_school', select_school_name);
        // uploadData.append('major_school_id', select_school_id);
        uploadData.append('graduation_year',graduation_year);


        return this.http.post(environment.apiUrl + 'api/profile/update_profile_again', uploadData).map((res) => {
            return res;
        });
    }

    public saveUserProfileImage(userImage): Observable<any> {
        
        const uploadData = new FormData();
        uploadData.append('image_profile', userImage, userImage.name);
        uploadData.append('user_id', this.userProfile.user_id);
        uploadData.append('auth_token', this.cookie.get('token'));
               
        return this.http.post(environment.apiUrl + 'api/profile/update_user_imge', uploadData).map((res) => {
            return res;
        });
    }

    public selectCollage(): Observable<any> {

        const formData = new FormData();
        formData.append('user_id', this.userProfile.user_id);
        formData.append('auth_token', this.cookie.get('token'));

        //let params = `user_id=${this.userProfile.user_id}&auth_token=${this.cookie.get('token')}`;
        return this.http.post(environment.apiUrl + 'api/profile/get_profile_page_details', formData).map((res) => {
            return res;
        });
    }

    public selectSchool(): Observable<any> {
        const formData = new FormData();
        formData.append('user_id', this.userProfile.user_id);
        formData.append('auth_token', this.cookie.get('token'));

        //let params = `user_id=${this.userProfile.user_id}&auth_token=${this.cookie.get('token')}`;
        return this.http.post(environment.apiUrl + 'api/profile/get_profile_page_details', formData).map((res) => {
            return res;
        });
    }

    public selectMajorByCollage(college_id): Observable<any> {

        const formData = new FormData();
        formData.append('user_id', this.userProfile.user_id);
        formData.append('auth_token', this.cookie.get('token'));
        formData.append('college_id', college_id);

        //let params = `user_id=${this.userProfile.user_id}&auth_token=${this.cookie.get('token')}&college_id=${college_id}`;
        return this.http.post(environment.apiUrl + 'api/profile/get_profile_majorlist', formData).map((res) => {
            return res;
        });
    }

    public getSearchCustomer(searchString = ''): Observable<any> {

        const formData = new FormData();
        formData.append('user_id', this.userProfile.user_id);
        formData.append('auth_token', this.cookie.get('token'));
        formData.append('search', searchString);

        //let params = `user_id=${this.userProfile.user_id}&auth_token=${this.cookie.get('token')}&search=${searchString}`;
        return this.http.post(environment.apiUrl + 'api/profile/get_profile_page_details', formData).map((res) => {
            return res;
        });
    }


    /**
    * Get slider images
    */
    // public getSliderImages(): Observable<any> {
    //     return this.http.get(environment.apiUrl + 'api/webfront/home_page_slider').map((res) => {
    //         return res;
    //     });
    // }


}