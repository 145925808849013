import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthInterceptor } from './shared/guard/auth-interceptor.guard';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { AuthGuard } from './shared/guard/auth.guard';
import { LoginService, ContactService, AlertService, UserService, CommonService, StaticPageService } from './shared/index';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule.forRoot(),
		SharedModule,
		Ng4LoadingSpinnerModule.forRoot(),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyAFgM81Qz-SwfTzUsr4F51AgDj0HdN88CQ'
		}),
		BrowserAnimationsModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		CookieService, AuthGuard, LoginService, ContactService, AlertService, UserService, CommonService, StaticPageService
	],
	bootstrap: [AppComponent]
})

export class AppModule { }
