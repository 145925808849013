import { Injectable } from '@angular/core';
import { Observable ,throwError} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map,retry } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { LoginService } from './login.service';


@Injectable()
export class StaticPageService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    /** Get Digital Materials data by api */
    public getTestimonials(obj): Observable<any> {
        const formData = new FormData();
        formData.append('start', obj.pageNo);


        return this.http.post(environment.apiUrl + 'api/webfront/get_testimonial', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /** Get testimonial for videos data by api */
    public getTestimonialsVideo(obj): Observable<any> {
        const formData = new FormData();
        formData.append('start', obj.pageNo);
        formData.append('course_id', obj.course_id);

        return this.http.post(environment.apiUrl + 'api/webfront/get_video_testimonial', formData).pipe(
            map(responce => {
                return responce;
            }),
            retry(2),
            catchError(this.handleError)
        );
    }

    public getPrivateTutoring(): Observable<any> {
        const formData = new FormData();

        return this.http.post(environment.apiUrl + 'api/webfront/get_private_courses_list', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }
    public getExitPopupData(): Observable<any> {
        return this.http.get(environment.apiUrl + 'api/webfront/get_net_form_details/').pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }


    public saveExitPopUpDetails(data: any): Observable<any> {
        const formData = new FormData();
        formData.append('user_email', data.user_email);
        formData.append('user_pass', data.user_pass);

        const is_login = this.loginService.checkAlreadyLogin();
        if (is_login) {
            const user = this.loginService.getUser();
            formData.append('user_id', user.user_id);
            formData.append('auth_token', user.token);
            return this.http.post(environment.apiUrl + 'api/webfront/set_net_form_details', formData).pipe(
                map(responce => {
                    return responce;
                }),
                catchError(this.handleError)
            );
        } else {
            return this.http.post(environment.apiUrl + 'api/webfront/set_net_form_details', formData).pipe(
                map(responce => {
                    return responce;
                }),
                catchError(this.handleError)
            );
        }

    }

    public applyCouponOnExitColse(objData: any): Observable<any> {
        const formData = new FormData();
        formData.append('package_boxes', objData.package_box);
        formData.append('promo_code', objData.promo_code);
        formData.append('course_id', objData.course_id);
        const is_login = this.loginService.checkAlreadyLogin();
        if (is_login) {
            const user = this.loginService.getUser();
            formData.append('user_id', user.user_id);
            formData.append('auth_token', user.token);
            return this.http.post(environment.apiUrl + 'api/webfront/aply_pcode_onall_boxs', formData).pipe(
                map(responce => {
                    return responce;
                }),
                catchError(this.handleError)
            );
        } else {
            return this.http.post(environment.apiUrl + 'api/webfront/aply_pcode_onall_boxs', formData).pipe(
                map(responce => {
                    return responce;
                }),
                catchError(this.handleError)
            );
        }

    }

    public getInviteText(): Observable<any> {
        const formData = new FormData();
        const is_login = this.loginService.checkAlreadyLogin();
        if (is_login) {
            const user = this.loginService.getUser();
            formData.append('user_id', user.user_id);
            formData.append('auth_token', user.token);
            return this.http.post(environment.apiUrl + 'api/webfront/invite_friends_text', formData).pipe(
                map(responce => {
                    return responce;
                }),
                catchError(this.handleError)
            );
        } else {
            return this.http.post(environment.apiUrl + 'api/webfront/invite_friends_text', formData).pipe(
                map(responce => {
                    return responce;
                }),
                catchError(this.handleError)
            );
        }
    }




    private handleError(error: Response | any): any {
        console.error('error===============', error);
        // return Observable.throw(error);
        return throwError(error);
    }




}