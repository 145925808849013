import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/map'

//custom services
import { environment } from "./../../../environments/environment"



@Injectable()
export class ContactService {

    constructor(private http: HttpClient) { }


    public submitContact(data: any): Observable<any> {
        const formData = new FormData();
        formData.append('email', data.email);
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        formData.append('message', data.message);

        return this.http.post(environment.apiUrl + 'api/owl/send_contact_us_email', formData).map(res => {
            return res;
        });
    }

}
