import { Component, OnInit } from "@angular/core";
// I import Location so that I can query the current path
import { Location } from "@angular/common";
// I also import Router so that I can subscribe to events
import { Router } from "@angular/router";
import { environment } from '../environments/environment';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "cram-tutoring";
  public spinerTimeOut = 10000;
  location: Location;
temp=false;
  ngOnInit() {
    if (environment.production) {
      if(location.href==='https://owltutoring.com/'){
        window.location.replace("https://cramtutoring.com/");
      }

      if (location.protocol === 'http:') {
        console.log('https://cramtutoring.com/');
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }
}
