import { Component, NgModule, ViewChild, TemplateRef, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthService,
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angular-6-social-login-v2';

import { LoginService } from './../../shared/services/login.service';
import { AlertService } from '../../shared/alert/alert.service';


@Component({
  selector: 'cram-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {

  private modalReference: NgbModalRef;

  @ViewChild('loginModal')
  private loginTempRef: TemplateRef<any>;
  public loginModalSubscription: Subscription;

  private user: any;

  constructor(private modalService: NgbModal, private loginService: LoginService,
    private authService: AuthService, private router: Router, private alertService: AlertService) {
  }

  /**
 *  This function is used for open modal
 * @param content
 */
  public openModal(content) {

    this.modalReference = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'login-modal' });
    this.modalReference.result.then((result) => {
    }, (reason) => {
      this.loginService.setLoginModalCloseStatus(true);
    });
  }




  /**
   * This method is used to close modal
   */
  public closeModal() {
    this.modalReference.close();
  }
  /**
 * This function is used to login using google Oauth
 * @returns Void
 */
  public signInWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (socialUser) => {
        this.user = socialUser;
        if (this.user) {
          this.onLogin();
        }
      }
    );


  }


  /**
 * This function is used to login using FB Oauth
 * @returns Void
 */
  public signInWithFB() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (socialUser) => {
        this.user = socialUser;
        if (this.user) {
          this.onLogin();
        }
      }
    );
  }

  /**
   * This function is used to call login service to get user login
   * @returns Void
   */
  public onLogin() {
    this.loginService.login(this.user).subscribe(data => {
      if (typeof data != "undefined" && data.response["status"]) {
        this.closeModal();
        this.loginService.setLogin(true);
        this.alertService.success('Login Success!');
        // window.location.reload();
      }
    }, error => {
      this.alertService.error('Error while login..');
    });
  }



  public subscibeInvokeEvent(): void {
    this.loginModalSubscription = this.loginService.getInvokeEvent().subscribe(value => {
      if (value) {
        this.openModal(this.loginTempRef);
      } else {
        this.loginModalSubscription.unsubscribe();
      }
    });

  }

  ngOnInit() {
    this.subscibeInvokeEvent();
  }



}
