import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  // transform(value: any, args?: any): any {
  //   return null;
  // }

  constructor(private sanitizer:DomSanitizer){}

  transform(html) {
    // console.log(html);
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
