import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'striphtml'
})

export class StripHtmlPipe implements PipeTransform {

    transform(value: string, expr: any): string {
        if (value) {
            let newValue = value.replace(/\s/g, "-");
            // replace(" ", "-").replace("  ", "")
            // console.log('old value======>', value, 'newValue=========>', newValue);
            return `${newValue}`;
        }
    }
    // transform(value: string): any {
    //     console.log('old value======>');

    //     return value.replace(/<.*?>/g, ''); // replace tags
    // }
}