import { Injectable } from '@angular/core';
import { Observable ,throwError} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map ,retry} from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { LoginService } from './login.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Output, EventEmitter } from '@angular/core';

@Injectable()
export class CommonService {
    public tasks: any = [];
    public hideShow: any;
    private isStatus = new BehaviorSubject(true);
    public currentStatus = this.isStatus.asObservable();

    @Output() refreshTopHeaderMenuEventEmitter: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient, private loginService: LoginService) { }

    /**
     * Used for get courses menu in video navigation
     * @returns Observable
     * @param
     */
    public getVedioMenuLink(): Observable<any> {
        const formData = new FormData();

        return this.http.post(environment.apiUrl + 'api/webfront/get_courses_list_for_all', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    public setHideToggle(isStatus) {
        this.isStatus.next(isStatus);
    }




    /**
     * get course details by course_id
     * @param course 
     * @return observable
     */
    public getCourseDetails(course): Observable<any> {
        let is_login = this.loginService.checkAlreadyLogin();
        let service_url = 'api/webfront/get_course_custom_all_data';
        const formData = new FormData();
        formData.append('course_id', course);

        if (is_login) {
            const user = this.loginService.getUser();
            service_url = 'api/webfront/get_course_custom_user_data';
            formData.append('user_id', user.user_id);
            formData.append('auth_token', user.token);

            // params = `course_id=${course}&user_id=${user.user_id}&auth_token=${user.token}`;
        }
        return this.http.post(environment.apiUrl + service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            retry(2),
            catchError(this.handleError)
        );
    }


    public getCourseLeftMenu(course): Observable<any> {
        let is_login = this.loginService.checkAlreadyLogin();
        let service_url = environment.apiUrl + 'api/webfront/get_custom_course_play_list';
        //let params = `course_id=${course}`;
        const formData = new FormData();
        formData.append('course_id', course);

        if (is_login) {
            const user = this.loginService.getUser();
            formData.append('user_id', user.user_id);
            formData.append('auth_token', user.token);
            // service_url = environment.apiUrl + 'api/courses/get_course_play_list';
            service_url = environment.apiUrl + 'api/courses/get_course_play_list_again';//new api changes name
            //params = `course_id=${course}&user_id=${user.user_id}&auth_token=${user.token}`;

        }
        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            retry(2),
            catchError(this.handleError)
        );
    }

    public getSingleVideoDetails(courseId, video_id): Observable<any> {
        let is_login = this.loginService.checkAlreadyLogin();
        if (is_login) {
            const user = this.loginService.getUser();
            // const service_url = environment.apiUrl + 'api/courses/get_video_details';
            const service_url = environment.apiUrl + 'api/courses/get_video_details_again';//new url

            const formData = new FormData();
            formData.append('course_id', courseId);
            formData.append('video_id', video_id);

            formData.append('user_id', user.user_id);
            formData.append('auth_token', user.token);

            //let params = `course_id=${courseId}&video_id=${video_id}&user_id=${user.user_id}&auth_token=${user.token}`;

            return this.http.post(service_url, formData).pipe(
                map(responce => {
                    return responce;
                }),
                catchError(this.handleError)
            );
        } else {
            this.handleError('user not logged in.');
        }
    }

    public submitVideoRating(post_id, rating, rating_msg) {

        let is_login = this.loginService.checkAlreadyLogin();
        if (is_login) {
            const user = this.loginService.getUser();
            // const service_url = environment.apiUrl + 'api/courses/get_video_details';
            const service_url = environment.apiUrl + 'api/webfront/submit_video_review';//new url
            const formData = new FormData();
            formData.append('topic_id', post_id);
            formData.append('topic_type', 'video');
            formData.append('rating', rating);
            formData.append('message', rating_msg);
            formData.append('user_id', user.user_id);
            formData.append('auth_token', user.token);
            return this.http.post(service_url, formData).pipe(
                map(responce => {
                    return responce;
                }),
                catchError(this.handleError)
            );
        } else {
            this.handleError('user not logged in..');
        }

    }

    public applyPromoCode(code, custom): Observable<any> {
        const user = this.loginService.getUser();
        const service_url = environment.apiUrl + 'api/courses/check_promo_code';


        const formData = new FormData();
        formData.append('code', code);
        formData.append('custom', custom);

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);


        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }


    public getPaidStatus(custom): Observable<any> {
        let user = this.loginService.getUser();
        let service_url = environment.apiUrl + 'api/webfront/success_from_paypal_web';

        const formData = new FormData();
        formData.append('custom', custom);

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);


        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /** Get Resume Services data by api */
    public getResumeServices(): Observable<any> {
        const formData = new FormData();

        return this.http.post(environment.apiUrl + 'api/webfront/get_resume_service', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    createEmbededLink(newVal) {
        if (newVal) {
            /*
            * Need to convert the urls into a friendly url that can be embedded and be used in the available online players the services have provided
            * for youtube: src="//www.youtube.com/embed/{{video_id}}"
            * for vimeo: src="http://player.vimeo.com/video/{{video_id}}
            */
            var embedFriendlyUrl = "",
                urlSections,
                index;

            if (newVal.indexOf("vimeo") >= 0) { // Displaying a vimeo video
                if (newVal.indexOf("player.vimeo") >= 0) {
                    embedFriendlyUrl = newVal;
                } else {
                    embedFriendlyUrl = newVal.replace("http:", "https:");
                    urlSections = embedFriendlyUrl.split(".com/");
                    embedFriendlyUrl = embedFriendlyUrl.replace("vimeo", "player.vimeo");
                    embedFriendlyUrl = embedFriendlyUrl.replace("/" + urlSections[urlSections.length - 1], "/video/" + urlSections[urlSections.length - 1] + "");
                }
            } else if (newVal.indexOf("youtu.be") >= 0) {

                index = newVal.indexOf(".be/");

                embedFriendlyUrl = newVal.slice(index + 4, newVal.length);
                embedFriendlyUrl = "https://www.youtube.com/embed/" + embedFriendlyUrl;
            } else if (newVal.indexOf("youtube.com") >= 0) { // displaying a youtube video
                if (newVal.indexOf("embed") >= 0) {
                    embedFriendlyUrl = newVal;
                } else {
                    embedFriendlyUrl = newVal.replace("/watch?v=", "/embed/");
                    if (embedFriendlyUrl.indexOf('m.youtube.com') != -1) {
                        embedFriendlyUrl = embedFriendlyUrl.replace("m.youtube.com", "youtube.com");
                    }
                }
            }

            return embedFriendlyUrl;
        }
    }

    /** Get FAQ data by api */
    public getFaq(): Observable<any> {
        const formData = new FormData();

        return this.http.post(environment.apiUrl + 'api/webfront/get_faq', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /** Get Digital Materials data by api */
    public getDigitalMaterials(): Observable<any> {
        const formData = new FormData();

        return this.http.post(environment.apiUrl + 'api/webfront/get_digital_materials', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }


    private handleError(error: Response | any): any {
        console.error('error===============', error);
        // return Observable.throw(error);
        return throwError(error);
    }

    /** Get Privacy Policy data by api */
    public getPrivacyPolicy(): Observable<any> {
        const formData = new FormData();

        return this.http.get(environment.apiUrl + 'api/webfront/privacy_policy_page', {}).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /** Get Terms of service data by api */
    public getTermsOfService(): Observable<any> {
        const formData = new FormData();

        return this.http.get(environment.apiUrl + 'api/webfront/terms_services_page/', {}).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /*get due date upcomming notification data */
    public getDueDateData(): Observable<any> {
        const user = this.loginService.getUser();
        const service_url = environment.apiUrl + 'api/webfront/get_due_dates';

        const formData = new FormData();

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);

        // let params = `user_id=${user.user_id}&auth_token=${user.token}`;
        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /*get due date professor list data */
    public getDueDateProfessor(ddncoursrid): Observable<any> {
        const user = this.loginService.getUser();
        const service_url = environment.apiUrl + 'api/webfront/get_professor_d_dates';

        const formData = new FormData();
        formData.append('course', ddncoursrid);

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);

        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /*get due date upcomming notification data */
    public getSettingNotification(): Observable<any> {
        let user = this.loginService.getUser();
        let service_url = environment.apiUrl + 'api/profile/get_notification_settings';
        //let params = `user_id=${user.user_id}&auth_token=${user.token}`;

        const formData = new FormData();
        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);

        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /**submit notification data */
    public submitNotificationData(notificationdata): Observable<any> {
        // console.log('testt',notificationdata);
        // let submitdata = notificationdata.notificationSettingArray;
        var obj = notificationdata.reduce(function (o, val) { o[val] = val; return o; }, {});
        //    console.log('obj tet',obj);
        let SettingCheckboxValue = JSON.stringify(obj);
        const user = this.loginService.getUser();

        const formData = new FormData();
        formData.append('notifiaction_setting', notificationdata);

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);

        return this.http.post(environment.apiUrl + 'api/profile/set_notification_settings_again', formData).map(res => {
            return res;
        });

    }

    /*get due date upcomming notification data */
    public appointmentNotification(email): Observable<any> {
        let user = this.loginService.getUser();
        let service_url = environment.apiUrl + 'api/webfront/get_my_appointment';

        const formData = new FormData();
        formData.append('user_email', email);

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);


        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }
    // api/webfront/cancel_appointment
    public cancelAppointment(apointmentid: any): Observable<any> {
        // console.log('service>>>',apointmentid)
        const user = this.loginService.getUser();
        const service_url = environment.apiUrl + 'api/webfront/cancel_appointment';

        const formData = new FormData();
        formData.append('appointmentid', apointmentid);
        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);


        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    // api/webfront/get_datetime_list
    public getDateTime(appointment_type_id: any, selected_date: any): Observable<any> {
        const user = this.loginService.getUser();
        const service_url = environment.apiUrl + 'api/webfront/get_datetime_list';

        const formData = new FormData();
        formData.append('appointmenttypeid', appointment_type_id);
        formData.append('date', selected_date);

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);


        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    // api/webfront/reschedule_appointment
    public updateAppointment(appointment_id: any, selected_date: any, time: any): Observable<any> {
        const user = this.loginService.getUser();
        const service_url = environment.apiUrl + 'api/webfront/reschedule_appointment';


        const formData = new FormData();
        formData.append('appointmentid', appointment_id);
        formData.append('selectedDate', selected_date);
        formData.append('selectedTime', time);

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);

        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    public videoCommenting(parent_id: any, message: any, postid: any): Observable<any> {
        let user = this.loginService.getUser();
        let service_url = environment.apiUrl + 'api/webfront/submit_comment';
        const formData = new FormData();
        formData.append('parent_id', parent_id);
        formData.append('message', message);
        formData.append('postid', postid);

        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);

        return this.http.post(service_url, formData).pipe(
            map(responce => {
                // console.log("kya", responce)
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /*this servicefuntion used for add due date data form submition */
    saveFiles(total_form, files, notification_id): Observable<any> {
        let user = this.loginService.getUser();
        let service_url = environment.apiUrl + 'api/webfront/update_due_date';
        // let httpHeaders = new HttpHeaders({
        //     'Content-Type': 'multipart/form-data',
        // });
        // let options = {
        //     headers: httpHeaders
        // };
        // console.log("files", files, total_form)
        let newDueDate = '';
        if (total_form.ddn_newduedate.day !== undefined) {
            newDueDate = ("0" + (total_form.ddn_newduedate.day)).slice(-2) + '-' + ("0" + (total_form.ddn_newduedate.month)).slice(-2) + '-' + total_form.ddn_newduedate.year;
        }
        let main_form: FormData = new FormData();
        // console.log("qwe", files)
        main_form.append('user_id', user.user_id);
        main_form.append('auth_token', user.token);
        main_form.append("due_date", newDueDate);
        main_form.append("ddn_newprofessor", total_form.ddn_newprofessor);
        main_form.append("ddn_newprofessor_name", '');
        main_form.append("ddn_othermessage", total_form.ddn_othermessage);
        main_form.append("ddn_post_id", notification_id);

        for (let i = 0; i < files.length; i++) {
            main_form.append("files[]", files[i], files[i]['name']);
            // console.log('form data variable :   ' + main_form);
        }
        return this.http.post(service_url, main_form).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );


    }

    /*get right sidebar data */
    public getRightSidebar(): Observable<any> {
        let user = this.loginService.getUser();
        let service_url = environment.apiUrl + 'api/webfront/video_tutoring_links';

        const formData = new FormData();


        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);

        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /**get right sidebar private tutoring */
    // https://cram-tutoring.rt.cisinlive.com/api/webfront/sidebar_ptpage/
    public getRightSidebarPvtTutoring(): Observable<any> {
        const user = this.loginService.getUser();
        const service_url = environment.apiUrl + 'api/webfront/sidebar_ptpage';

        const formData = new FormData();


        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);

        return this.http.post(service_url, formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }


    public getScheduleAppoinmentRecordId(record_id): Observable<any> {
        const user = this.loginService.getUser();
        const formData = new FormData();
        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);
        formData.append('record_id', record_id);

        return this.http.post(environment.apiUrl + 'api/webfront/private_booking_calendar', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    public getScheduleAppoinmentTutorId(tutor_id): Observable<any> {
        const user = this.loginService.getUser();
        const formData = new FormData();
        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);
        formData.append('tutor_id', tutor_id);

        return this.http.post(environment.apiUrl + 'api/webfront/private_booking_by_tutor/', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    public getScheduleAppoinmentAppointmentType(tutor_id, appointmenttype): Observable<any> {
        const user = this.loginService.getUser();
        const formData = new FormData();
        formData.append('user_id', user.user_id);
        formData.append('auth_token', user.token);
        formData.append('tutor_id', tutor_id);
        formData.append('tutor_id', tutor_id);

        return this.http.post(environment.apiUrl + 'api/webfront/private_booking_calendar_by_tutor/', formData).pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /**
     * both emitter used for image refresh header data
     */
    public emitTopHeaderMenuUpdatedEvent(parameters) {
        this.refreshTopHeaderMenuEventEmitter.emit(parameters);
    }

    public getRefreshTopHeaderMenuEventEmitter() {
        return this.refreshTopHeaderMenuEventEmitter;
    }

    /**
     * social icon for footer
     */
    public media_social_link() {
        // api/webfront/footer_social_links
        return this.http.post(environment.apiUrl + 'api/webfront/footer_social_links/', '').pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

    /**
    * home home_flip_video_url container get api
    */
    public home_flip_video_url() {
        // https://cram-tutoring.rt.cisinlive.com/api/webfront/home_click_corse/
        return this.http.post(environment.apiUrl + 'api/webfront/home_click_corse/', '').pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );

    }

    /**
     * home founder container get api
     */
    public home_founder() {
        return this.http.post(environment.apiUrl + 'api/webfront/home_founder/', '').pipe(
            map(responce => {
                return responce;
            }),
            catchError(this.handleError)
        );
    }

}

