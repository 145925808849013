import { Injectable, Inject, forwardRef } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/operator/do';
import { LoginService } from './../../shared/index';
import { AlertService } from '../alert/alert.service';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private cookie: CookieService,
    private auth: LoginService, private alertService: AlertService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // request = request.clone({
    //   setHeaders: {
    //      'Content-Type': 'multipart/form-data',
    //     }
    // });

     //'Content-Type':  'multipart/form-data',
    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {

      }
    }, (err: any) => {

      if (err instanceof HttpErrorResponse) {
        if (err.status > 400 && err.status < 404) {

          this.auth.logout();
          this.auth.setLogin(false);
          this.router.navigate(['']);

          console.error('401-403 : unauthorized access');
        } else if (err.status == 500) {
          // TODO- notify
          console.error(err.status, 'Server error');
        }else{
          if(err){
            this.alertService.error('Internal server error');
          }
        }
      }
    });
  }
}
