import { ErrorHandler,Inject,Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService, CommonService, AlertService, StaticPageService } from './shared/index';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  constructor(@Inject(Injector) private injector: Injector) { 
    // super(true);
  }
  // Need to get AlertService from injector rather than constructor injection to avoid cyclic dependency error
  private get alertService(): AlertService {
    return this.injector.get(AlertService);
  }

  handleError(error: any): void {
   const chunkFailedMessage = /Loading chunk .* failed/;
    
    // when chunk failed it reloads the page
    if (chunkFailedMessage.test(error.message)) {
        console.log('Reloading page...')
      window.location.reload();
    }

    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        console.log('No Internet Connection');
        this.alertService.warn('No Internet Connection!');

      } else {
        // Handle Http Error (error.status === 403, 404...)
        // console.log('status',error.status)
      }
   } else {
     console.log('else block of error',error);
     if(error.error=='popup_closed_by_user'){
        this.alertService.error('Unable to login might be cookies are disabled in browser. please check!');
     }
     // Handle Client Error (Angular Error, ReferenceError...)     
   }
  // Log the error anyway

  // console.error('It happens: ', error);
  // super.handleError(error);
  }

  
}