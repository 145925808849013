import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, NgForm } from '@angular/forms';
import { ContactService } from './../services/contact.service'
import { AlertService } from '../../shared/alert/alert.service';

@Component({
  selector: 'cram-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public contactForm: FormGroup;
  public submitted = false;

  constructor(private fb: FormBuilder, private contactservice: ContactService, private alertService: AlertService) {
    this.createForm();
  }

  createForm() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      phone: ['', Validators.required],
    });
  }

  onSubmit(f: any) {
    this.submitted = true;
    if (this.contactForm.invalid) {
      this.alertService.error('Please fill all details..');
      return;
    }
    const formData = {
      name: <any>f.controls.name.value,
      email: <any>f.controls.email.value,
      phone: <any>f.controls.phone.value,
      message: <any>f.controls.message.value,
    };
    this.contactservice.submitContact(formData).subscribe((data) => {
      if (data.status) {
        this.contactForm.reset();
        this.alertService.success(data.msg);
      } else {
        this.alertService.error(data.msg);
      }

    });
  }



  ngOnInit() {
  }

}
