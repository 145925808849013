import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of as observableOf,throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs/Subject';
import { environment } from './../../../environments/environment';

@Injectable()
export class LoginService {

  public apiUrl = environment.apiUrl;
  public isLoggedin = false;
  public loggedinChange: EventEmitter<boolean> = new EventEmitter();
  public loggedinProfileImgHeader: EventEmitter<boolean> = new EventEmitter();
  public invokeEvent = new Subject<string>();
  public invokeCloseModalEvent = new Subject<string>();



  constructor(private http: HttpClient, private cookie: CookieService) { }

  /**
   *  This function is used for call function of component
   * @param boolean 
   */
  openLoginModal(modelStatus) {
    this.invokeEvent.next(modelStatus);
  }

  getInvokeEvent(): Observable<any> {
    return this.invokeEvent.asObservable();
  }

  /**
   * This function is used to set modal close status
   * @param modelStatus 
   */
  setLoginModalCloseStatus(modelStatus) {
    this.invokeCloseModalEvent.next(modelStatus);
  }

  getModalCloseEvent(): Observable<any> {
    return this.invokeCloseModalEvent.asObservable();
  }

  /**
   * 
   * @param loginData object type
   * @returns response
   */
  public login(loginData): any {
    let provider_id: string;
    if (loginData.provider == 'GOOGLE') { provider_id = 'googleid'; } else { provider_id = 'facebookid'; }
    const formData = new FormData();
    formData.append('email', loginData.email);
    formData.append('name', loginData.name);
    formData.append(provider_id, loginData.id);

    formData.append('googletoken', loginData.authToken);
    formData.append('photo', loginData.photoUrl);
    formData.append('device_type', 'web');

    return this.http.post(this.apiUrl + 'api/owl/get_authenticate', formData).pipe(
      map(responce => {
        if (responce["status"]) {
          this.cookie.set('userData', JSON.stringify(responce['response']), 30, "/");
          this.cookie.set('token', responce["response"]['token'], 30, "/");
          this.cookie.set('user_picture', responce["response"]['user_picture'], 30, "/");
          this.cookie.set('user_id', responce['response']['user_id'], 30, "/");
          this.cookie.set('isLoggedIn', 'true', 30, "/");
        }
        return responce;
      }),
      catchError(this.handleError)
    );
  }

  /**
   * @param 
   * @returns String
   */
  public getToken(): string {
    const cookieExists: boolean = this.cookie.check('token');
    if (cookieExists) {
      return this.cookie.get('token');
    }
  }

  public getUser(): any {
    if (this.cookie.check('token') && this.cookie.check('user_id')) {
      return { token: this.cookie.get('token'), user_id: this.cookie.get('user_id') };
    } else {
      return { token: '', user_id: '' };
    }

  }

  public logout(): void {
    this.cookie.delete('userData','/');
    this.cookie.delete('token','/');
    this.cookie.delete('user_picture','/');
    this.cookie.delete('user_id','/');
    this.cookie.delete('isLoggedIn','/');
    this.cookie.deleteAll();
    setTimeout(function(){
    console.log("Reload");
    window.location.reload();
  }, 400);
  }

  public isAuthenticated(): boolean {
    if (this.getToken()) {
      return true;
    }
    return false;
  }


  public checkAlreadyLogin(): boolean {
    const cookieExists: boolean = this.cookie.check('isLoggedIn');
    // console.log('checkAlreadyLogin',cookieExists,this.cookie.get('isLoggedIn'));
    if (cookieExists && this.cookie.get('isLoggedIn') == 'true') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError(error: Response | any): any {
    console.error('error===============', error);
    // return Observable.throw(error);
    return throwError(error);
  }


  public setLogin(isLoggedin: boolean): void {
    this.isLoggedin = isLoggedin;
    this.loggedinChange.emit(this.isLoggedin);
  }

  public changeLoginStatus() {
    return this.loggedinChange;
  }

  public setProfileImgHeader(profileImg) {
    // console.log("profileImg",profileImg);
    this.loggedinProfileImgHeader.emit(profileImg);
  }

  public getProfileImgHeader() {
    return this.loggedinProfileImgHeader;
  }



}
