import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact/contact.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AlertComponent } from './alert/alert.component';
import { LoginComponent } from './login/login.component';
import {NgxMaskModule} from 'ngx-mask';
import { ReplacePipe } from './replace.pipe';
import { StripHtmlPipe } from './StripHtml.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot()

  ],
  declarations: [ContactComponent, AlertComponent, LoginComponent,ReplacePipe,StripHtmlPipe,SafeHtmlPipe],
  exports: [ContactComponent, AlertComponent, LoginComponent,ReplacePipe,StripHtmlPipe,SafeHtmlPipe]
})
export class SharedModule { }
